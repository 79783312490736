<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <create-prd-cat />
      </v-col>
      <v-col cols="8">
        <table-prd-cat />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ProductCategoryIndex',
  components: {
    CreatePrdCat: () => import('./Create'),
    TablePrdCat: () => import('./Table'),
  },
}
</script>
